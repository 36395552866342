import Layout from "layout";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  useAddBanner,
  useUpdateBanner,
  useGetBanner,
  useImageUpload,
} from "utils/api/user.api";
import { type Banner } from "utils/types/user.type";
// import Banner1 from "assets/images/Banner1.png";
import AddButtonIcon from "assets/icons/AddbuttonIcon.svg";
import Modal from "components/modal";
import FileIcon from "assets/icons/fileIcon.svg";
import DropIcon from "assets/icons/DropIcon.svg";
import Loader from "sharedUI/loader";

export default function BannerAdvertisement() {
  // const { data, isLoading } = useGetUserList();
  const { data } = useGetBanner();

  console.log("🚀 ~ BannerAdvertisement ~ data:", data);

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [selectedBanner, setSelectedBanner] = useState<Banner | null>(null);

  const handleAddNewBanner = () => {
    setModalType("add");
    setSelectedBanner(null);
    setOpenModal(true);
  };

  const handleEditImage = (banner: Banner) => {
    console.log("🚀 ~ handleEditImage ~ banner:", banner);
    setModalType("edit");
    setSelectedBanner(banner);
    setOpenModal(true);
  };

  return (
    <Layout>
      <div className="dashboard-main " style={{ backgroundColor: " #FAFAFA" }}>
        <div
          className="bg-cyan-800 rounded-lg"
          style={{
            position: "relative",
            height: "122px",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "24px",
          }}
        >
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
            }}
          >
            Banner & Advertisement
          </text>
        </div>
        <div className="main-wrapper max-w-full py-6">
          <div className="dashboard-sidebar table-wrapper max-h-[27rem] overflow-auto ">
            <table
              className="w-full border border-[#F1F1F1] "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 sticky top-0 z-10">
                <tr className="flex py-4">
                  <th className="flex-1  px-5 text-left">Image</th>
                  <th className="flex-1  text-left">Status</th>
                  <th className="flex-1  text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <TableRow
                    key={index}
                    banner={item}
                    onEdit={handleEditImage}
                    // setOpenModal={setOpenModal}
                  />
                ))}
                {data?.length === 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <button
            onClick={handleAddNewBanner}
            className="flex gap-2 text-[#0291D1] text-xs bg-[#EDF9FF] border border-[#0291D1] font-[400] rounded-lg px-4 py-2.5 whitespace-nowrap ml-2"
          >
            <img src={AddButtonIcon} alt="icon" />
            ADD NEW IMAGE
          </button>
        </div>
      </div>
      <BannerModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        modalType={modalType}
        banner={selectedBanner}
      />
    </Layout>
  );
}

function TableRow({
  banner,
  onEdit,
}: {
  onEdit: (banner: Banner) => void;

  banner: Banner;
}) {
  console.log("🚀 ~ banner:", banner);
  const { mutate: updateBanner, isSuccess } = useUpdateBanner();
  const handleDelete = () => {
    updateBanner({
      bannerId: banner?.id,
      mediaUrl: banner?.mediaUrl ?? "",
      isActive: banner?.isActive ?? false,
      isDeleted: true,
    });
    console.log("bannerID", banner?.id);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Banner Deleted successfully!");
    }
  }, [isSuccess]);
  return (
    <tr className="flex border-b border-[#F1F1F1] bg-[#FFFFFF]">
      <td className="flex-1 py-2 px-4 rounded-l-lg">
        <div className="flex w-[max-content]">
          <img className="w-32 h-12 mr-3" src={banner?.mediaUrl} alt="image" />
        </div>
      </td>
      <td className="flex-1 py-2 px-5 text-left flex items-center">
        <p className="text-[#8F92A1] text-xs">
          {banner?.isActive === true ? "Active" : "InActive"}
        </p>
      </td>
      <td className="flex-1 py-2  rounded-r-lg text-center flex items-center justify-center">
        <button
          className="text-[#030319] text-xs bg-[#F1F1F1] border border-[#8F92A1] font-medium rounded-lg px-4 py-2.5 whitespace-nowrap"
          onClick={() => onEdit(banner)}
        >
          EDIT
        </button>
        <button
          onClick={handleDelete}
          className="text-[#EF4444] text-xs bg-[#FFD8D8] border border-[#EF4444] font-[400] rounded-lg px-7 py-2.5 whitespace-nowrap ml-2"
        >
          DELETE
        </button>
      </td>
    </tr>
  );
}

function BannerModal({
  isOpen,
  onClose,
  modalType,
  banner,
}: {
  isOpen: boolean;
  onClose: () => void;
  modalType: "add" | "edit";
  banner: Banner | null;
}) {
  console.log("🚀 ~ banner:", banner);
  const { mutate: addBanner } = useAddBanner();
  const { mutate: updateBanner } = useUpdateBanner();
  const [loading, setLoading] = useState(false);

  const [bannerData, setBannerData] = useState({
    bannerId: "",
    mediaUrl: "",
    isActive: false,
  });

  const { mutate: imageUpload } = useImageUpload();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setLoading(true);
      imageUpload(e.target.files[0], {
        onSuccess: (response) => {
          console.log("🚀 ~ handleFileChange ~ response:", response);
          setLoading(false);
          // Handle the response, for example, updating state with the uploaded image URL
          setBannerData((prev) => ({ ...prev, mediaUrl: response?.url }));
        },
      });
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === "Active";

    setBannerData((prev) => ({
      ...prev,
      isActive: value,
    }));
  };

  const handleSubmit = () => {
    if (modalType === "add") {
      addBanner({ mediaUrl: bannerData?.mediaUrl });
    } else if (modalType === "edit" && banner) {
      updateBanner({ ...bannerData, isDeleted: false });
    }
    onClose(); // Close the modal after submission
  };
  useEffect(() => {
    setBannerData({
      bannerId: banner?.id ?? "",
      mediaUrl: banner?.mediaUrl ?? "",
      isActive: banner?.isActive ?? false,
    });
  }, [banner]);
  useEffect(() => {
    setBannerData({
      bannerId: banner?.id ?? "",
      mediaUrl: banner?.mediaUrl ?? "",
      isActive: banner?.isActive ?? false,
    });
  }, [onClose]);

  return (
    <>
      {loading && <Loader />}
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="flex flex-col items-center overflow-auto gap-4 w-full h-full">
          <div
            className="overflow-auto"
            style={{
              display: "flex",
              gap: "16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h1 className="text-[18px] font-[600] text-[#030319]">
              {modalType === "add" ? "Add New Image" : "Edit "}
            </h1>
          </div>
          {modalType === "add" && (
            <div className="w-full items-start">
              <h1 className="text-[14px] font-[400] text-[#555555]">
                Upload you banner image here.
              </h1>
            </div>
          )}
          <div className="w-full py-4">
            {modalType === "edit" ? (
              bannerData?.mediaUrl ? (
                <>
                  <label htmlFor="fileInput" className="cursor-pointer">
                    <img
                      src={bannerData?.mediaUrl}
                      alt="banner"
                      className="w-full h-50 rounded-lg"
                    />
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </>
              ) : (
                <div className="flex flex-col items-center gap-2 p-7 border border-dashed border-[#8F92A1] rounded-lg mt-1">
                  <img src={FileIcon} alt="file" />
                  <p className="text-[12px] font-[400] text-[#8F92A1]">
                    Upload File Here
                  </p>
                  <label
                    htmlFor="fileInput"
                    className="bg-[#EDF9FF] text-[#0291D1] text-xs font-medium border border-[#0291D1] rounded-lg px-8 py-2.5 transition cursor-pointer"
                  >
                    BROWSE
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    className="w-full text-[#8F92A1] outline-none hidden"
                    onChange={handleFileChange}
                  />
                </div>
              )
            ) : bannerData?.mediaUrl ? (
              <img
                src={bannerData?.mediaUrl}
                alt="banner"
                className="w-full h-50"
              />
            ) : (
              <div className="flex flex-col items-center gap-2 p-7 border border-dashed border-[#8F92A1] rounded-lg mt-1">
                <img src={FileIcon} alt="file" />
                <p className="text-[12px] font-[400] text-[#8F92A1]">
                  Upload File Here
                </p>
                <label
                  htmlFor="fileInput"
                  className="bg-[#EDF9FF] text-[#0291D1] text-xs font-medium border border-[#0291D1] rounded-lg px-8 py-2.5 transition cursor-pointer"
                >
                  BROWSE
                </label>
                <input
                  id="fileInput"
                  type="file"
                  className="w-full text-[#8F92A1] outline-none hidden"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
          {modalType === "edit" && (
            <div className="w-full mb-4">
              <label className="text-[#8F92A1] text-[14px] text-[400]">
                Status
              </label>
              <div className="flex items-center p-3 border border-[#E0E3E8] rounded-lg mt-1">
                <select
                  className="appearance-none  outline-none w-full rounded-lg"
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                    background: `url(${DropIcon}) no-repeat  right  1rem center / 1em`,
                  }}
                  value={bannerData?.isActive ? "Active" : "Inactive"}
                  onChange={handleStatusChange}
                >
                  <option className="text-[14px] text-gray-400" value="Active">
                    Active
                  </option>

                  <option
                    className="text-[14px] text-gray-400"
                    value="Inactive"
                  >
                    Inactive
                  </option>
                </select>
              </div>
            </div>
          )}
          <div className="w-full flex items-center justify-between gap-2">
            <button
              onClick={onClose}
              className="w-full text-[#000000] text-[16px] font-medium bg-[#F8F8F8] rounded-lg px-6 py-4"
            >
              CANCEL
            </button>
            <button
              onClick={handleSubmit}
              className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border rounded-lg px-6 py-4 transition"
            >
              {modalType === "add" ? "UPLOAD" : "UPDATE"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
