import React, { useEffect } from "react";
import LockImg from "assets/images/LockImg.png";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useResetPassword } from "utils/api/auth.api";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Input } from "components/input";
import backIcon from "assets/icons/back.svg";
// import WalletIcon from "assets/images/WalletIcon.svg";
import Logo from "../../assets/icons/logo.svg";

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

export default function ResetPassword() {
  const navigate = useNavigate();

  const { mutate: resetPassword, isSuccess, isLoading } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordForm>();

  const onSubmit: SubmitHandler<ResetPasswordForm> = (data) =>
    resetPassword(data?.password);

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
      toast.success("Password reset successfully!");
    }
  }, [isSuccess]);

  const userPassword = watch("password");

  return (
    <section className="login-section  h-screen ">
      <div className="flex flex-col h-full ">
        <header
          style={{
            paddingTop: "30px",

            paddingLeft: "30px",

            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {" "}
          <img style={{ height: "50px" }} src={Logo} alt="" />{" "}
          {/* <span className="text-stone-700 text-x font-bold whitespace-nowrap">
            CRYPTO WALLET
          </span> */}
        </header>
        <div className="grid lg:grid-cols-12 h-screen gap-3">
          <div className="lg:col-span-6 flex items-center justify-center">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "40px" }}
              className="login-wrapper w-full max-w-md mx-auto p-6"
            >
              <Link
                style={{ display: "flex", gap: "14px", marginBottom: "0px" }}
                className=" text-black-800 text-[18px] font-[Poppins] font-bold mb-8 xl:mb-16"
                to="/login"
              >
                {" "}
                <img src={backIcon} alt="" /> Back
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h5
                  style={{}}
                  className=" text-black-800 text-[32px] font-[Poppins] font-bold "
                >
                  CHANGE PASSWORD
                </h5>
                <text style={{ color: "#8F92A1" }}>
                  Please enter a secure password that is at least 8 characters
                  long and includes letters, numbers, and a special character.{" "}
                </text>
              </div>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises  */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <Input
                    isInvalid={Boolean(errors?.password)}
                    errorMessage={errors?.password?.message}
                    label="New Password"
                    type="password"
                    name="password"
                    placeholder="********"
                    register={register}
                    rules={{
                      required: "Password is required",
                      validate: {
                        isCharacter: (value: string) =>
                          // eslint-disable-next-line
                          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
                            value
                          ) || "Must contain one Special character",
                        // eslint-disable-next-line
                        isSmall: (value: string) =>
                          /[a-z]/.test(value) ||
                          "Must contain one Small letter",
                        isCapital: (value: string) =>
                          /[A-Z]/.test(value) ||
                          "Must contain one Capital character",
                        isDigit: (value: string) =>
                          /\d/.test(value) ||
                          "Must contain one Digit character",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length should be 8",
                      },
                    }}
                  />
                </div>

                <div className="mb-4">
                  <Input
                    isInvalid={Boolean(errors?.confirmPassword)}
                    errorMessage={errors?.confirmPassword?.message}
                    label="Confirm New password"
                    type="password"
                    placeholder="********"
                    name="confirmPassword"
                    register={register}
                    rules={{
                      required: "Re-enter Password",
                      validate: {
                        isMatch: (value: string) =>
                          userPassword === value || "Password did not match",
                      },
                    }}
                  />
                </div>

                <button
                  style={{ marginTop: "30px" }}
                  type="submit"
                  className="bg-cyan-800  hover:text-cyan-800 hover:bg-cyan-300 text-white text-[18px] font-[400] border border-cyan-800 rounded-lg w-full p-3.5 transition"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      className="w-8 h-8 rounded-full animate-spin mx-auto
                  border-4 border-solid border-cyan-800 border-t-transparent"
                    ></div>
                  ) : (
                    "UPDATE PASSWORD"
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="lg:col-span-6 relative lg:overflow-hidden">
            <div className="login-bg h-full flex items-center justify-center">
              <img
                className="max-w-xs lg:max-w-none object-contain"
                src={LockImg}
                style={{ height: "680px" }}
                alt="title"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
