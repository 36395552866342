import React, { useState, useEffect } from "react";
import Layout from "layout";
// import Modal from "components/modal";
import toast from "react-hot-toast";
import {
  useGetDepositAccount,
  useUpdateDepositAccount,
} from "utils/api/user.api";
import { type DepositAccount } from "utils/types/user.type";

export const AccountManagemnt = () => {
  const { data } = useGetDepositAccount();
  const { mutate: updateAccount, isSuccess } = useUpdateDepositAccount();
  // console.log("🚀 ~ AccountManagemnt ~ account:", account);

  console.log("🚀 ~ AccountManagemnt ~ data:", data);

  // const [bankDetailOpen, setBankDetailOpen] = useState(false);
  // const [MTNOpen, setMTNOpen] = useState(false);
  // const [OrangeMoneyOpen, setOrangeMoneyOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<DepositAccount | null>(null);

  const handleUpdateClick = (account: DepositAccount) => {
    setModalData(account);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalData(null);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setModalData((prevData) =>
      prevData ? { ...prevData, [name]: value } : null
    );
  };

  const handleToggleChange = (e: any) => {
    const { name, checked } = e.target;
    setModalData((prevData) =>
      prevData ? { ...prevData, [name]: checked } : null
    );
  };
  const UpdateAccount = () => {
    if (modalData) {
      updateAccount(modalData);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("User updated successfully!");
      closeModal();
    }
  }, [isSuccess]);

  return (
    <>
      <Layout>
        <div
          className="dashboard-main h-screen  "
          style={{ backgroundColor: "#FAFAFA" }}
        >
          <div
            className="bg-cyan-800 rounded-lg"
            style={{
              position: "relative",
              height: "122px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "24px",
            }}
          >
            <span
              style={{
                fontWeight: "500",
                fontSize: "32px",
                color: "white",
              }}
            >
              Account Management
            </span>
          </div>
          <div className="mt-6 flex flex-wrap gap-8 pb-10">
            {data?.map((account, index) => (
              <div key={index} className="rounded-xl w-[500px] shadow-md">
                <div className="flex justify-between items-center bg-[#E7E7E7] px-5 border-b border-b-[#E7E7E7] rounded-t-xl">
                  <h1 className="text-[16px] font-[600] text-[#030319] py-5">
                    {account.accountType === "Bank"
                      ? "Bank Account Detail"
                      : account.accountType === "Mtn"
                      ? "MTN Money Transfer"
                      : "Orange Money"}
                  </h1>
                </div>
                <div className="p-5">
                  {account.accountType === "Bank" && (
                    <>
                      <div className="flex justify-between items-center pb-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Account Title
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.bankTitle}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Bank Name
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.bankName}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Account Number
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.bankNumber}
                        </h2>
                      </div>
                      <div className="flex flex-col justify-start items-start py-5">
                        <h1 className="text-[14px] font-[400] text-[#000000]">
                          Info
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#616161] pt-1">
                          {account?.instructions}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 ">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Enable Deposit
                        </h1>
                        <label className="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={account?.isDepositEnabled}
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                        </label>
                      </div>
                      <div className="flex justify-between items-center py-5">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Enable Withdrawal
                        </h1>
                        <label className="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={account?.isWithdrawEnabled}
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                        </label>
                      </div>
                    </>
                  )}
                  {account.accountType === "Mtn" && (
                    <>
                      <div className="flex justify-between items-center pb-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Name
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.mtnName}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Phone Number
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.mtnNumber}
                        </h2>
                      </div>
                      <div className="flex flex-col justify-start items-start py-5">
                        <h1 className="text-[14px] font-[400] text-[#000000]">
                          Info
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#616161] pt-1">
                          {account?.instructions}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 ">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Enable Deposit
                        </h1>
                        <label className="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={account?.isDepositEnabled}
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                        </label>
                      </div>
                      <div className="flex justify-between items-center py-5">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Enable Withdrawal
                        </h1>
                        <label className="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={account?.isWithdrawEnabled}
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                        </label>
                      </div>
                    </>
                  )}
                  {account.accountType === "Orange" && (
                    <>
                      <div className="flex justify-between items-center pb-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Name
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.orangeName}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 border-b border-b-[#E7E7E7]">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Phone Number
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#000000]">
                          {account.orangeNumber}
                        </h2>
                      </div>
                      <div className="flex flex-col justify-start items-start py-5">
                        <h1 className="text-[14px] font-[400] text-[#000000]">
                          Info
                        </h1>
                        <h2 className="text-[14px] font-[400] text-[#616161] pt-1">
                          {account?.instructions}
                        </h2>
                      </div>
                      <div className="flex justify-between items-center py-5 ">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Enable Deposit
                        </h1>
                        <label className="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={account?.isDepositEnabled}
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                        </label>
                      </div>
                      <div className="flex justify-between items-center py-5">
                        <h1 className="text-[14px] font-[400] text-[#616161]">
                          Enable Withdrawal
                        </h1>
                        <label className="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={account?.isWithdrawEnabled}
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                        </label>
                      </div>
                    </>
                  )}
                  <button
                    onClick={() => handleUpdateClick(account)}
                    className="w-full flex justify-center items-center text-[#FFFFFF] text-[16px] font-[400] bg-[#0291D1] rounded-lg py-3 mt-4"
                  >
                    UPDATE DETAIL
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative flex flex-col  bg-white rounded-lg overflow-auto-lg py-4 px-6 gap-2 max-w-[505px] w-full">
            <h2 className="text-[18px] font-[600] text-[#030319] ">
              {modalData?.accountType === "Bank"
                ? "Update Bank Account"
                : modalData?.accountType === "Mtn"
                ? "Update MTN Account"
                : "Update Orange Account"}
            </h2>
            {modalData?.accountType === "Bank" && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Account Title
                  </label>
                  <input
                    type="text"
                    name="bankTitle"
                    defaultValue={modalData?.bankTitle}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    name="bankName"
                    defaultValue={modalData?.bankName}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Account Number
                  </label>
                  <input
                    type="text"
                    name="bankNumber"
                    defaultValue={modalData?.bankNumber}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Instructions
                  </label>
                  <textarea
                    name="instructions"
                    defaultValue={modalData.instructions}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="flex justify-between items-center py-5 ">
                  <h1 className="text-[14px] font-[400] text-[#616161]">
                    Enable Deposit
                  </h1>
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      name="isDepositEnabled"
                      checked={modalData?.isDepositEnabled}
                      className="sr-only peer"
                      onChange={handleToggleChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                  </label>
                </div>
                <div className="flex justify-between items-center py-5">
                  <h1 className="text-[14px] font-[400] text-[#616161]">
                    Enable Withdrawal
                  </h1>
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      name="isWithdrawEnabled"
                      checked={modalData?.isWithdrawEnabled}
                      className="sr-only peer"
                      onChange={handleToggleChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                  </label>
                </div>
              </>
            )}
            {modalData?.accountType === "Mtn" && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="mtnName"
                    defaultValue={modalData?.mtnName}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="mtnNumber"
                    defaultValue={modalData.mtnNumber}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Instructions
                  </label>
                  <textarea
                    name="instructions"
                    defaultValue={modalData.instructions}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="flex justify-between items-center py-5 ">
                  <h1 className="text-[14px] font-[400] text-[#616161]">
                    Enable Deposit
                  </h1>
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      name="isDepositEnabled"
                      checked={modalData?.isDepositEnabled}
                      className="sr-only peer"
                      onChange={handleToggleChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                  </label>
                </div>
                <div className="flex justify-between items-center py-5">
                  <h1 className="text-[14px] font-[400] text-[#616161]">
                    Enable Withdrawal
                  </h1>
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      name="isWithdrawEnabled"
                      checked={modalData?.isWithdrawEnabled}
                      className="sr-only peer"
                      onChange={handleToggleChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                  </label>
                </div>
              </>
            )}
            {modalData?.accountType === "Orange" && (
              <>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="orangeName"
                    defaultValue={modalData.orangeName}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="orangeNumber"
                    defaultValue={modalData.orangeNumber}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Instructions
                  </label>
                  <textarea
                    name="instructions"
                    defaultValue={modalData.instructions}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
                <div className="flex justify-between items-center py-5 ">
                  <h1 className="text-[14px] font-[400] text-[#616161]">
                    Enable Deposit
                  </h1>
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      name="isDepositEnabled"
                      checked={modalData?.isDepositEnabled}
                      className="sr-only peer"
                      onChange={handleToggleChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                  </label>
                </div>
                <div className="flex justify-between items-center py-5">
                  <h1 className="text-[14px] font-[400] text-[#616161]">
                    Enable Withdrawal
                  </h1>
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      name="isWithdrawEnabled"
                      checked={modalData?.isWithdrawEnabled}
                      className="sr-only peer"
                      onChange={handleToggleChange}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full     peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#0291D1]"></div>
                  </label>
                </div>
              </>
            )}
            <div className="w-full flex items-center justify-between gap-2">
              <button
                onClick={closeModal}
                className="w-full text-[#000000] text-[16px] font-medium  bg-[#F8F8F8]  rounded-lg px-6 py-4"
              >
                CANCEL
              </button>
              <button
                onClick={UpdateAccount}
                className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border rounded-lg px-6 py-4 transition"
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
