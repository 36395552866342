import Layout from "layout";
import React, { useEffect, useState } from "react";
import {
  useGetUserList,
  useGetUserWalletBalance,
  useUpdateUser,
} from "utils/api/user.api";
import { type ListedUser } from "utils/types/user.type";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import avatar from "assets/images/avatar.png";
import searchIconSrc from "assets/icons/searchIcon.svg";
import DropIcon from "assets/icons/DropIcon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "components/modal";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  fullname: yup.string().required("Fullname is required"),
  // lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/^\+?1?\d{9,15}$/, "Invalid phone number")
    .required("Phone number is required"),
  country: yup.string().required("Country is required"),
  status: yup
    .string()
    .oneOf(["active", "de-active"], "Invalid status")
    .required("Status is required"),
});

export default function UserDictionary() {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") ?? ""
  );

  const { data, isLoading } = useGetUserList();
  console.log("🚀 ~ UserDictionary ~ data:", data);
  return (
    <Layout>
      <div className="dashboard-main " style={{ backgroundColor: " #FAFAFA" }}>
        <div className=" bg-cyan-800  relative flex flex-col lg:flex-row justify-between p-5 lg:p-6 items-center rounded-lg h-36 ">
          <text className="font-[500] text-[24px] lg:text-[32px] text-[#FFFFFF]">
            User Management
          </text>

          <div className="relative w-full lg:w-auto">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by Email "
              className="w-full lg:w-[390px] text-grey-700 text-sm rounded-lg border border-grey-600 focus:outline-0 py-3.5 px-5 pr-10"
            />
            <img
              src={searchIconSrc}
              alt="Search"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
            />
          </div>
        </div>
        <div className="main-wrapper max-w-full py-6">
          <div className="dashboard-sidebar table-wrapper max-h-[30rem] overflow-auto ">
            <table
              className="w-full border border-[#F1F1F1]"
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 sticky top-0 z-10">
                <tr>
                  <th className="py-3 px-5 text-left">Username</th>
                  <th className="py-3 px-2 text-left">Name</th>
                  <th className="py-3 px-2 text-left">Email</th>
                  <th className="py-3 px-2 text-left">Phone</th>

                  <th className="py-3 px-2 text-left">Country</th>

                  <th className="py-3 px-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.filter((item) => item.email.includes(searchValue))
                  ?.map((item) => <TableRow key={item?.id} user={item} />)}
                {!isLoading &&
                  data?.filter((item) => item.email.includes(searchValue))
                    ?.length === 0 && (
                    <tr>
                      <td>No Results Found</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ user }: { user: ListedUser }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [isEdit, setIsEdit] = useState(false);
  // const [isActive] = useState(Boolean(user?.isActive));

  const { mutate: updateUserData, isSuccess } = useUpdateUser();
  const { data: userWallet } = useGetUserWalletBalance(user?.id);
  console.log("🚀 ~ TableRow ~ userWallet:", userWallet);
  const [openModal, setOpenModal] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const onSubmit = (data: any) => {
    const updateData = { userId: user.id, ...data };
    updateUserData(updateData);
  };

  useEffect(() => {
    if (isSuccess) {
      setEditOpen(!editOpen);
      toast.success("User updated successfully!");
    }
  }, [isSuccess]);

  const handleEditClick = () => {
    console.log("user", user);
    setValue("fullname", user.fullname || "");
    // setValue("lastName", user.lastName || "");
    setValue("email", user.email || "");
    setValue("phone", user.phone || "");
    setValue("country", user.country || "USA");
    setValue("status", user.isActive ? "active" : "de-active");
    setEditOpen(true);
  };

  return (
    <>
      <tr className="border-b border-gray-400 bg-[#FFFFFF]">
        <td className="py-5 px-4 rounded-l-lg">
          <div className="flex w-[max-content]">
            <p className="text-[14px] font-[400] text-[#8F92A1]">
              {user?.username || "@floyd"}
            </p>
          </div>
        </td>
        <td className="py-5 ">
          <p className="text-[14px] font-[400] text-[#8F92A1]">
            {user?.fullname}
          </p>
        </td>
        <td className="py-5 ">
          <p className="text-[14px] font-[400] text-[#8F92A1]">{user?.email}</p>
        </td>
        <td className="py-5">
          <p className="text-[14px] font-[400] text-[#8F92A1]">{user?.phone}</p>
        </td>
        <td className="py-5 ">
          <p className="text-[14px] font-[400] text-[#8F92A1]">
            {user?.country}
          </p>
        </td>

        <td className="flex gap-1 py-5  rounded-r-lg w-[100px]">
          <>
            <button
              className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1]  font-medium rounded-lg px-4 py-2.5 whitespace-nowrap"
              onClick={handleEditClick}
            >
              EDIT INFO
            </button>
          </>

          <button
            className="text-gray-800 text-xs  bg-[#F1F1F1] border border-[#8F92A1]   font-medium rounded-lg px-2 py-2.5 whitespace-nowrap"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            DETAIL
          </button>
        </td>
      </tr>
      <Modal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
          setIsEdit(!isEdit);
        }}
      >
        <div className="flex flex-col items-center overflow-auto gap-8 w-full h-full">
          <div
            className=" overflow-auto "
            style={{
              display: "flex",

              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              border: "2px solid #0291D1",
              borderWidth: "0 0 2px 0",
              padding: "0px 0px 16px 0px",
            }}
          >
            <div className="flex ">
              <img
                src={user?.image || avatar}
                className="w-12 h-12 object-cover rounded-full mr-3"
                alt="title"
              />
              <div>
                <p className="text-black-900 text-[18px] font-[700] ">
                  {user?.fullname}
                </p>
                <p className="text-[#8F92A1] text-[14px] font-[400]">
                  {user?.email}
                </p>
              </div>
            </div>
            <div>
              <p className="text-black-900 text-[14px] font-[400]">
                Date Created
              </p>
              <p className="text-[#8F92A1] text-[14px] font-[400]">
                {new Date(user?.createdAt)
                  .toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/\//g, "-")}
              </p>
            </div>
          </div>
          {isEdit && (
            <div
              className="dashboard-sidebar w-[99%] flex my-2 flex-col gap-10 overflow-y-auto overflow-x-hidden  border border-[#F1F1F1] rounded-lg p-3"
              style={{ maxHeight: "calc(5 * 50px)" }}
            >
              {userWallet?.balance?.length &&
                userWallet?.balance?.map((item) => (
                  <div key={item?.id} className="">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>
                        <h6 className="text-[16px] font-bold">{item?.name}</h6>
                        <p className="text-sm text-[#8F92A1] font-[400]">
                          {item?.address}
                        </p>
                      </div>
                      <div className="flex gap-2 mt-2">
                        <div className="flex">
                          <span className="font-bold text-[14px]">
                            {new Intl.NumberFormat("en", {
                              maximumFractionDigits: 4,
                            }).format(item?.balance)}{" "}
                          </span>
                          <span className="whitespace-nowrap">
                            {item?.symbol}
                          </span>
                        </div>
                        <img src={item?.logoUrl} className="w-6 h-6" />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
              border: "1px solid #F1F1F1",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <div className="basis-5/12 border-b border-[#F1F1F1] pb-2">
              <h6 className="text-sm font-[400] text-[#8F92A1]">
                {userWallet?.wallet?.usdCurrency?.name} Account
              </h6>
              <div className="flex justify-between w-full items-center ">
                <span className="font-semibold">
                  {userWallet?.wallet?.accountNumber
                    ? userWallet?.wallet?.accountNumber
                    : "6483994084"}
                </span>
                <span className="font-semibold">
                  {userWallet?.wallet?.usdCurrency?.symbol}{" "}
                  {userWallet?.wallet?.totalBalanceInUsd &&
                    new Intl.NumberFormat("en", {
                      maximumFractionDigits: 4,
                    }).format(userWallet?.wallet?.totalBalanceInUsd)}
                </span>
              </div>
            </div>
            <div className="basis-5/12">
              <h6 className="text-sm font-[400] text-[#8F92A1]">
                {userWallet?.wallet?.localCurrency?.name} Account
              </h6>
              <div className="flex justify-between w-full items-center">
                <span className="font-semibold">
                  {userWallet?.wallet?.accountNumber
                    ? userWallet?.wallet?.accountNumber
                    : "6483994084"}
                </span>
                <span className="font-semibold">
                  {userWallet?.wallet?.localCurrency?.symbol}{" "}
                  {userWallet?.wallet?.totalBalanceInLocalCurrency &&
                    new Intl.NumberFormat("en", {
                      maximumFractionDigits: 4,
                    }).format(userWallet?.wallet?.totalBalanceInLocalCurrency)}
                </span>
              </div>
            </div>
          </div>

          {/* <select
            name="select-1"
            id="select-1"
            className="max-w-md w-full border border-black-800/30 px-3 py-2.5 rounded-lg focus:outline-0 mb-0"
            onChange={(e) => handleUpdateUser(e?.target?.value)}
            defaultValue={user?.isActive ? "active" : "de-active"}
          >
            <option className="bg-black-300" value="active">
              Activate
            </option>
            <option className="bg-black-300" value="de-active">
              Deactivate
            </option>
          </select> */}
          <div className="flex items-center justify-between gap-2 w-full">
            <button
              onClick={() => {
                setIsEdit(!isEdit);
                setOpenModal(false);
              }}
              className="w-full text-[#000000] text-[16px] bg-[#F8F8F8] font-medium rounded-lg px-6 py-4"
            >
              CANCEL
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={editOpen} onClose={() => setEditOpen(false)}>
        <div className=" w-full h-full ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center overflow-auto gap-4 w-full h-full "
          >
            <div
              className=" overflow-auto "
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <h1 className="text-[18px] font-[600] text-[#030319]">
                Edit User Info
              </h1>
              <h1 className="text-[14px] font-[400] text-[#555555]">
                Update user personal info
              </h1>
            </div>
            <div className=" w-full  ">
              <div className="flex gap-4 w-full mb-3">
                <div className=" w-full  ">
                  <label className="text-[#8F92A1] text-[14px] text-[400]">
                    Full Name
                  </label>
                  <div
                    className={`flex items-center p-3 border border-[#E0E3E8]  rounded-lg mt-1`}
                  >
                    <input
                      className="w-full text-[#000000]  font-[400] text-[16px]   outline-none"
                      placeholder="Johan"
                      {...register("fullname")}
                    />
                  </div>
                  {errors?.fullname && (
                    <p className="text-red-500 font-normal text-[10px]">
                      {errors?.fullname?.message}
                    </p>
                  )}
                </div>{" "}
                {/* <div className=" w-full mb-3 ">
                <label className="text-[#8F92A1] text-[14px] text-[400]">
                  Last Name
                </label>
                <div
                  className={`flex items-center p-3 border border-[#E0E3E8]  rounded-lg mt-1`}
                >
                  <input
                    className="w-full text-[#000000]  font-[400] text-[16px]     outline-none"
                    placeholder="Doe"
                    {...register("lastName")}
                  />
                </div>
                {errors?.lastName && (
              <p className="text-red-500 font-normal text-[10px]">
                {errors?.lastName?.message}
              </p>
            )}
              </div> */}
              </div>
              <label className="text-[#8F92A1] text-[14px] text-[400] ">
                Email
              </label>
              <div
                className={`flex items-center  border border-[#E0E3E8]  rounded-lg mt-1`}
              >
                <input
                  disabled
                  className="w-full text-[#000000] p-3 font-[400] text-[16px]    outline-none "
                  placeholder="john@email.com"
                  {...register("email")}
                />
              </div>
              {errors.email && (
                <p className="text-red-500 font-normal text-[10px]">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className=" w-full  ">
              <label className="text-[#8F92A1] text-[14px] text-[400]">
                Phone No
              </label>
              <div
                className={`flex items-center p-3 border border-[#E0E3E8]  rounded-lg mt-1`}
              >
                <input
                  className="w-full text-[#000000]  font-[400] text-[16px]    outline-none"
                  placeholder=""
                  {...register("phone")}
                />
              </div>
              {errors.phone && (
                <p className="text-red-500 font-normal text-[10px]">
                  {errors.phone.message}
                </p>
              )}
            </div>
            <div className=" w-full  ">
              <label className="text-[#8F92A1] text-[14px] text-[400]">
                Country
              </label>
              <select
                // name="select-1"
                id="select-1"
                className="appearance-none w-full border border-[#E0E3E8] text-[#000000]  font-[400] text-[16px]   p-3 rounded-lg focus:outline-0 mb-0 mt-1"
                defaultValue={"USA"}
                {...register("country")}
                style={{
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  appearance: "none",
                  background: `url(${DropIcon}) no-repeat  right  1rem center / 1em`,
                }}
              >
                <option className="bg-black-300" value="UsA">
                  USA
                </option>
                <option className="bg-black-300" value="Pakistan">
                  Pakistan
                </option>
                <option className="bg-black-300" value="UK">
                  UK
                </option>
              </select>
              {errors.country && (
                <p className="text-red-500 font-normal text-[10px]">
                  {errors.country.message}
                </p>
              )}
            </div>
            <select
              // name="select-1"
              id="select-1"
              className="appearance-none w-full border border-[#E0E3E8] text-[#000000]  font-[400] text-[16px]   p-3 rounded-lg focus:outline-0 mb-3"
              defaultValue={"de-active"}
              {...register("status")}
              style={{
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                background: `url(${DropIcon}) no-repeat  right  1rem center / 1em`,
              }}
            >
              <option className="bg-black-300" value="active">
                Activate
              </option>
              <option className="bg-black-300" value="de-active">
                Deactivate
              </option>
            </select>
            {errors.status && (
              <p className="text-red-500 font-normal text-[10px]">
                {errors.status.message}
              </p>
            )}

            <div className="w-full flex items-center justify-between gap-2">
              <button
                onClick={() => setEditOpen(false)}
                className="w-full text-[#000000] text-[16px] font-medium  bg-[#F8F8F8]  rounded-lg px-6 py-4"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="w-full bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[16px] font-medium border  rounded-lg px-6 py-4 transition"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
