import React, { useEffect } from "react";
import { Input } from "components/input";
import { useUpdatePassword } from "utils/api/user.api";
import { type UpdateUserPassword } from "utils/types/user.type";
import { type SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";

interface ChangePasswordProps {
  setPasswordModalOpen?: (open: boolean) => void;
}

export default function ChangePasswordModal({
  setPasswordModalOpen,
}: ChangePasswordProps) {
  const { mutate: updatePassword, isSuccess, isLoading } = useUpdatePassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset: resetForm,
  } = useForm<UpdateUserPassword>();

  const onSubmit: SubmitHandler<UpdateUserPassword> = (data) =>
    updatePassword(data);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success("Password updated successfully!");
    }
  }, [isSuccess]);

  const newPassword = watch("password");
  return (
    <div className="w-full">
      <div className="w-full">
        <ul className="flex flex-wrap items-center gap-3 my-5">
          <li>
            <p className="text-black-900 font-bold text-xl">
              Change your password
            </p>
          </li>
        </ul>
        <form className="max-w-lg mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Input
              type="password"
              label="Old Password"
              register={register}
              placeholder="********"
              errorMessage={errors?.oldPassword?.message}
              isInvalid={Boolean(errors?.oldPassword)}
              name="oldPassword"
              rules={{
                required: "Old Password is required",
              }}
              inputProps={{
                className:
                  "w-full bg-[#FAFAFA] border border-black-300 px-4 py-3 rounded-lg focus:outline-0",
              }}
            />
          </div>

          <div className="mb-4">
            <Input
              type="password"
              label="New Password"
              register={register}
              placeholder="********"
              errorMessage={errors?.password?.message}
              isInvalid={Boolean(errors?.password)}
              name="password"
              rules={{
                required: "Password is required",
                validate: {
                  isCharacter: (value: string) =>
                    // eslint-disable-next-line no-useless-escape
                    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]/.test(value) ||
                    "Must contain one Special character",
                  isSmall: (value: string) =>
                    /[a-z]/.test(value) || "Must contain one Small letter",
                  isCapital: (value: string) =>
                    /[A-Z]/.test(value) || "Must contain one Capital character",
                  isDigit: (value: string) =>
                    /\d/.test(value) || "Must contain one Digit character",
                },
                minLength: {
                  value: 8,
                  message: "Minimum length should be 8",
                },
              }}
              inputProps={{
                className:
                  "w-full bg-[#FAFAFA] border border-black-300 px-4 py-3 rounded-lg focus:outline-0",
              }}
            />
          </div>

          <div className="mb-6">
            <Input
              type="password"
              label="Confirm New Password"
              placeholder="*******"
              register={register}
              errorMessage={errors?.confirmPassword?.message}
              isInvalid={Boolean(errors?.confirmPassword)}
              name="confirmPassword"
              rules={{
                required: "Confirm password is required",
                validate: {
                  isCharacter: (value) =>
                    value === newPassword || "Confirm password does not match",
                },
              }}
              inputProps={{
                className:
                  "w-full bg-[#FAFAFA] border border-black-300 px-4 py-3 rounded-lg focus:outline-0",
              }}
            />
          </div>

          <div className="w-full flex items-center justify-end gap-5">
            <button
              type="button"
              onClick={() => {
                resetForm();
                if (setPasswordModalOpen) {
                  setPasswordModalOpen(false);
                }
              }}
              className="text-[#000000]  bg-[#F8F8F8] font-[400] rounded-lg px-8 py-3 w-full text-[18px] "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-[18px] font-[400] border border-cyan-800 rounded-lg px-8 py-3 transition w-full"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="w-5 h-5 rounded-full animate-spin mx-auto border-2 border-solid border-cyan-800 border-t-transparent"></div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
