import React from "react";
import { FadeLoader } from "react-spinners";
const Loader = () => {
  return (
    <div className="fixed w-full h-full bg-[rgba(0,0,0,0.6)] top-0 left-0 flex justify-center items-center z-[99999]">
      <FadeLoader color="#20A8D9" size={10} radius={2} />
    </div>
  );
};
export default Loader;
