import React, { useState } from "react";
// import { EyeOffIcon } from "assets/icons";
import {
  type UseFormRegister,
  type FieldValues,
  type RegisterOptions,
  type Path,
} from "react-hook-form";
import PassShowIcon from "../../assets/icons/passShowIcon.svg";
import PassHideIcon from "../../assets/icons/passHideIcon.svg";
export interface FormInputProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register: UseFormRegister<TFormValues>;
  isInvalid: boolean;
  type: string;
  label: string;
  placeholder?: string;
  errorMessage?: string;
  inputProps?: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLInputElement> &
    React.InputHTMLAttributes<HTMLInputElement>;
}

export const Input = <TFormValues extends FieldValues>({
  isInvalid,
  label,
  name,
  rules,
  errorMessage,
  type,
  register,
  placeholder,
  inputProps,
}: FormInputProps<TFormValues>) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <label className="block text-black-800 text-base font-medium mb-3">
        {label}
      </label>
      <div className="relative mb-2">
        <input
          {...register(name, rules)}
          type={isShow ? "text" : type}
          placeholder={placeholder}
          className={`w-full bg-gray-100 px-4 py-3.5 rounded-lg focus:outline-0 ${
            isInvalid ? "border-red-500" : "border-none"
          }`}
          {...inputProps}
        />

        {type === "password" && (
          <div>
            {isShow ? (
              <img
                src={PassShowIcon}
                onClick={() => setIsShow(!isShow)}
                alt="icon"
                className="absolute top-4 right-3"
              />
            ) : (
              <img
                src={PassHideIcon}
                onClick={() => setIsShow(!isShow)}
                alt="icon2"
                className="absolute top-3 right-3"
              />
            )}
          </div>
        )}
      </div>

      {isInvalid && <span className="text-red-800">{errorMessage}</span>}
    </>
  );
};
