import Layout from "layout";
import React, { useState } from "react";
import { useGetKycUser, useUpdateKyc } from "utils/api/user.api";
import { type KycUser } from "utils/types/user.type";
import searchIconSrc from "assets/icons/searchIcon.svg";
// import passportImg from "assets/images/passportImg.png";
// import pdfIcon from "assets/icons/pdfIcon.svg";

export default function Kyc() {
  const [kycStatus, setKycStatus] = useState(false);
  const { data } = useGetKycUser(kycStatus);
  console.log("🚀 ~ Kyc ~ data:", data);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("KYC");

  const handleTabClick = (tab: any) => {
    if (tab === "HISTORY") {
      setKycStatus(true);
      setActiveTab(tab);
    } else {
      setKycStatus(false);
      setActiveTab(tab);
    }
  };

  return (
    <Layout>
      <div className="dashboard-main " style={{ backgroundColor: " #FAFAFA" }}>
        <div className=" bg-cyan-800  relative flex flex-col lg:flex-row justify-between p-5 lg:p-6 items-center rounded-lg h-36">
          <text className="font-[500] text-[24px] lg:text-[32px] text-[#FFFFFF]">
            KYC
          </text>

          <div className="relative w-full lg:w-auto ">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by Email "
              className=" text-grey-700 text-sm rounded-lg border border-grey-600 focus:outline-0 py-3.5 px-5 pr-10 w-full lg:w-[390px]"
            />
            <img
              src={searchIconSrc}
              alt="Search"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
            />
          </div>
        </div>
        <div className="w-full flex  justify-center items-center  mx-auto pt-6">
          <div className="relative right-0 w-full ">
            <ul
              className="relative flex justify-start items-center   list-none  border-b-2 border-[#F2F5F8]"
              role="list"
            >
              <li
                className={` flex w-[300px] text-center justify-center ${
                  activeTab === "KYC" ? "border-b-2 border-[#0291D1]" : ""
                }`}
              >
                <a
                  className={` flex items-center justify-center  px-0 py-1 mb-0 transition-all ease-in-out border-0  cursor-pointer text-slate-700 ${
                    activeTab === "KYC" ? "text-[#000000] font-[600] " : ""
                  }`}
                  onClick={() => handleTabClick("KYC")}
                  role="tab"
                  aria-selected={activeTab === "KYC"}
                  aria-controls="KYC"
                >
                  <span className="ml-1">KYC</span>
                </a>
              </li>
              <li
                className={` flex w-[300px] text-center justify-center ${
                  activeTab === "HISTORY" ? "border-b-2 border-[#0291D1]" : ""
                }`}
              >
                <a
                  className={` flex items-center justify-center  px-0 py-1 mb-0 transition-all ease-in-out border-0  cursor-pointer text-slate-700 ${
                    activeTab === "HISTORY" ? "text-[#000000] font-[600] " : ""
                  }`}
                  onClick={() => handleTabClick("HISTORY")}
                  role="tab"
                  aria-selected={activeTab === "HISTORY"}
                  aria-controls="HISTORY"
                >
                  <span className="ml-1">HISTORY</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`${
            activeTab === "KYC" ? "block opacity-100" : "hidden opacity-0"
          } main-wrapper max-w-full py-6`}
          id="KYC"
          role="tabpanel"
        >
          <div className="table-wrapper overflow-auto max-w-full ">
            <table
              className="w-full border border-[#F1F1F1] "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-0 z-10">
                <tr className="bg-cyan-300  ">
                  <th style={{ width: "25%" }} className="py-3 px-5 text-left">
                    Name
                  </th>
                  <th style={{ width: "25%" }} className="py-3 px-5 text-left">
                    Date
                  </th>
                  <th style={{ width: "25%" }} className="py-3 px-5 text-left">
                    Status
                  </th>
                  <th
                    style={{ width: "25%" }}
                    className="py-3 px-5 text-left"
                    colSpan={2}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.filter((item) => item.fullname.includes(searchValue))
                  ?.map((item) => (
                    <TableRow key={item?.id} row={item} activeTab={activeTab} />
                  ))}
                {data?.filter((item) => item.fullname.includes(searchValue))
                  ?.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center py-4">
                      No Results Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`${
            activeTab === "HISTORY" ? "block opacity-100" : "hidden opacity-0"
          } main-wrapper max-w-full py-6`}
          id="HISTORY"
          role="tabpanel"
        >
          <div className="table-wrapper overflow-auto max-w-full ">
            <table
              className="w-full border border-[#F1F1F1] "
              style={{ borderCollapse: "collapse", borderSpacing: "0" }}
            >
              <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-0 z-10">
                <tr className="bg-cyan-300  ">
                  <th style={{ width: "25%" }} className="py-3 px-5 text-left">
                    Name
                  </th>
                  <th style={{ width: "25%" }} className="py-3 px-5 text-left">
                    Date
                  </th>
                  <th style={{ width: "25%" }} className="py-3 px-5 text-left">
                    Status
                  </th>
                  <th
                    style={{ width: "25%" }}
                    className="py-3 px-5 text-left"
                    colSpan={2}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.filter((item) => item.fullname.includes(searchValue))
                  ?.map((item) => (
                    <TableRow key={item?.id} row={item} activeTab={activeTab} />
                  ))}
                {data?.filter((item) => item.fullname.includes(searchValue))
                  ?.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center py-4">
                      No Results Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
interface TableRowProps {
  row: KycUser;
  activeTab: string;
}
function TableRow({ row, activeTab }: TableRowProps) {
  const [isReview, setReview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { mutate: updateKyc } = useUpdateKyc();

  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800 bg-[#F1F1F1]  border-[#8F92A1]";

  const toggleDetail = () => {
    setReview(!isReview);
    setIsEdit(!isEdit);
  };

  const handleApprove = () => {
    updateKyc({
      userId: row.id,
      kycStatus: "Approved",
    });
  };

  const handleReject = () => {
    updateKyc({
      userId: row.id,
      kycStatus: "Rejected",
    });
  };

  // const [zoomedImageId, setZoomedImageId] = useState(null);

  // const handleZoom = (id: any) => {
  //   setZoomedImageId(zoomedImageId === id ? null : id);
  // };

  return (
    <>
      <tr className="bg-[#FFFFFF]">
        <td className="border-b border-grey-800/30 py-5 px-5">
          <p className="text-[#8F92A1] font-[400] text-[14px] leading-tight ">
            {row?.fullname}
          </p>
        </td>
        <td className="border-b border-grey-800/30 rounded-l-lg py-5 px-2">
          <p className="text-[#8F92A1] font-[400] text-[14px] leading-tight ml-3">
            {new Date(row?.createdAt).toLocaleString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <strong
            className={`${
              activeTab === "HISTORY"
                ? row?.kycStatus === "Approved"
                  ? "text-[#22C55E]"
                  : "text-[#EF4444]"
                : "text-[#EF4444]"
            }       text-[14px] font-[400] text-center`}
          >
            {row?.kycStatus}
          </strong>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <button
            onClick={() => {
              toggleDetail();
            }}
            type="button"
            className={`text-xs font-medium rounded-lg px-8 py-2.5 border ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isReview && (
        <tr>
          <td colSpan={5} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex w-full">
                  <td className="flex-1" valign="top">
                    <strong className="text-x">Full Name</strong>
                    <span className="text-gray-400 mt-3 text-xs leading-relaxed block">
                      {row?.fullname}
                    </span>
                  </td>
                  <td className="flex-1">
                    <strong className="text-x">Identity Documents </strong>
                    <text className="text-gray-400">{row?.idType}</text>
                    {row?.idType === "Passport" ? (
                      <>
                        <span className="text-cyan-800 mt-3 text-xs leading-relaxed block">
                          Passport Image
                        </span>
                        <div className="cursor-pointer overflow-hidden z-50">
                          <a
                            href={row?.idFrontProof}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={row?.idFrontProof}
                              alt="Passport"
                              className="w-40 h-24 cursor-pointer"
                            />
                          </a>
                        </div>
                      </>
                    ) : (
                      <div className="flex gap-2">
                        <div>
                          <span className="text-cyan-800 mt-3 text-xs leading-relaxed block">
                            Front
                          </span>
                          <div className="cursor-pointer overflow-hidden z-50">
                            <a
                              href={row?.idFrontProof}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={row?.idFrontProof}
                                alt="ID Front"
                                className="w-40 h-28 rounded-lg"
                              />
                            </a>
                          </div>
                        </div>
                        <div>
                          <span className="text-cyan-800 mt-3 text-xs leading-relaxed block">
                            Back
                          </span>
                          <div className="cursor-pointer overflow-hidden z-50">
                            <a
                              href={row?.idBackProof}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={row?.idBackProof}
                                alt="ID Back"
                                className="w-40 h-28 rounded-lg"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="flex-1 text-center" valign="top">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <strong className="text-x">Selfie Proof</strong>
                      <div className="mt-3 text-center w-full">
                        <p className="text-[#0291D1] text-[12px] font-[400]">
                          Selfie Image
                        </p>
                        <div className="z-50 w-full flex justify-center cursor-pointer">
                          <a
                            href={row?.selfie}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={row?.selfie}
                              alt="Selfie"
                              className="w-24 h-24 rounded-lg"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>

                  {activeTab === "KYC" && (
                    <td className="flex-1" valign="top">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <button
                          type="button"
                          onClick={handleReject}
                          className="text-black text-[15px] font-[400] bg-[#E4E4E4]  rounded-lg px-5 py-2"
                          // disabled={isLoading}
                        >
                          {/* <div className="w-5 h-5 rounded-full  mx-auto border-2 border-solid border-cyan-800 border-t-transparent"></div> */}
                          REJECT
                        </button>
                        <button
                          onClick={handleApprove}
                          type="button"
                          className="bg-cyan-800   text-white text-xs font-medium  rounded-lg px-5 py-2 transition"
                          // disabled={isLoading}
                        >
                          {/* <div className="w-5 h-5 rounded-full  mx-auto border-2 border-solid border-white border-t-transparent"></div> */}
                          APPROVE
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
